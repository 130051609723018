<template>
  <section id="reset-password">
    <v-form
      id="reset-password-form"
      ref="resetPassword"
      @submit.prevent="submitHandler"
      v-model="isFormValid"
    >
      <v-row no-gutters>
        <v-col cols="12" class="d-flex justify-center">
          <h2
            class="subtitle-1 text--primary font-weight-large font--secondary pb-6"
          >
            {{ title }}
          </h2>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-1">
        <v-col cols="12">
          <password-input-field
            isRequired
            label="New Password"
            :password="formData.password"
            :rules="[isNewPasswordValid]"
            @update-password="formData.password = $event"
          />
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-1">
        <v-col cols="12">
          <password-input-field
            isRequired
            label="Confirm New Password"
            :password="formData.confirmPassword"
            :rules="[validateConfirmPassword, isConfirmPasswordSame]"
            @update-password="formData.confirmPassword = $event"
            hide-details="auto"
          />
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-2">
        <v-col cols="12">
          <template v-for="{ text, isValid } in passwordRules">
            <v-row :key="text" no-gutters>
              <v-col cols="1" class="d-flex align-center py-lg-1">
                <v-icon :color="getIconColor({ text, isValid })" small>
                  {{ isValid ? "mdi-check-circle" : "mdi-close-circle" }}
                </v-icon>
              </v-col>
              <v-col
                cols="11"
                :class="[
                  { 'red--text': !isValid && isNewPasswordDirty },
                  $vuetify.breakpoint.lg ? 'caption' : 'errors',
                ]"
                class="py-1 text--secondary"
              >
                {{ text }}
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="mt-1">
          <v-btn
            color="primary"
            :loading="isLoading"
            :disabled="!isFormValid || isFormDisabled"
            type="submit"
            block
            class="button font-weight-bold"
          >
            {{ btnText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </section>
</template>

<script>
import { maxLength } from "@/validators";
import { PasswordValidatorMixin } from "@/mixins";
import { PasswordInputField } from "@/components/shared";

/**
 * Forgot Password form
 */
export default {
  name: "ResetPasswordForm",
  /**
   * ---------------- Mixins ----------------
   */
  mixins: [PasswordValidatorMixin],
  /**
   * ---------------- Components ----------------
   */
  components: {
    PasswordInputField,
  },
  /**
   * ---------------- Components ----------------
   */
  props: {
    title: { type: String, required: true },
    onSubmitHandler: { type: Function, required: true },
    btnText: { type: String, required: true },
    isLoading: { type: Boolean, required: true, default: false },
    isFormDisabled: { type: Boolean, default: false },
    displayMessages: { type: Object, required: true, default: () => ({}) },
  },
  /**
   * ---------------- Data properties ----------------
   */
  data() {
    return {
      isFormValid: false,
    };
  },
  /**
   * ---------------- Methods ----------------
   */
  methods: {
    maxLength,
    /**
     * Submit handler
     */
    submitHandler() {
      if (!this.$refs.resetPassword.validate()) {
        return false;
      }
      this.onSubmitHandler(this.formData);
    },
  },
};
</script>
<style scoped>
.errors {
  font-size: 0.75em;
}
</style>
