var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"right-nav__drawer pa-8",attrs:{"fixed":"","right":"","temporary":"","width":"30%","id":"edit-permission-form"},model:{value:(_vm.isDrawerOpen),callback:function ($$v) {_vm.isDrawerOpen=$$v},expression:"isDrawerOpen"}},[_c('div',{staticClass:"d-flex justify-space-between flex-wrap"},[_c('h5',{staticClass:"text-h5 text--primary font--secondary font-weight-large"},[_vm._v(" Edit Permission ")]),_c('tooltip',{attrs:{"name":"Close"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"text-dark-grey",on:{"click":function($event){$event.stopPropagation();return _vm.closeNavDrawer($event)}}},[_vm._v(" mdi-close ")])],1)]}}])})],1),_c('v-divider',{staticClass:"my-4 border-light-grey"}),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',{attrs:{"no-gutters":"","id":"user-permissions-wrapper"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"overline pb-4 font-weight-large"},[_vm._v("USER INFORMATION")]),_c('div',{staticClass:"caption text--secondary pb-1"},[_vm._v("Email Address")]),_c('div',{staticClass:"body-2 pb-6"},[_vm._v(_vm._s(_vm.user.email))]),(_vm.user.first_name)?_c('div',{staticClass:"caption text--secondary pb-1 text-capitalize"},[_vm._v(" Name ")]):_vm._e(),_c('div',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.firstName))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"mb-6 border-light-grey"}),_c('div',{staticClass:"mb-4 overline text--primary"},[_c('label',{staticClass:"overline font-weight-large",attrs:{"for":"user-permission-roles"}},[_vm._v(" USER ACCESS ")])]),_c('v-select',{staticClass:"required",attrs:{"id":"user-permission-roles","items":_vm.roles,"rules":[_vm.required('Role')],"label":"Role","menu-props":{ bottom: true },"hide-details":"","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',{staticClass:"body-2 text-black--80 font--primary"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(item))+" ")])],1)]}}]),model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}})],1)],1),_c('v-divider',{staticClass:"mb-6 mt-5 border-light-grey"}),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"px-4 font-weight-bold",attrs:{"type":"submit","color":"primary","loading":_vm.isLoading,"disabled":!_vm.isFormValid}},[_vm._v(" Save Changes ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }