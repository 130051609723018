<template>
  <div id="inbox-threshold-settings" class="mb-6">
    <card-widget>
      <template #cardBody>
        <v-card-title class="pa-0">
          <div
            id="threshold-title"
            class="d-inline-flex text--primary align-center pb-2"
          >
            <h6 class="text-h6 font-weight-large">
              {{ formConfig.thresholds.title }}
            </h6>
            <span class="pl-1 text--secondary body-2 d-flex">
              (Optional)
            </span>
          </div>

          <div class="text--secondary subtitle-1 pb-3 w-100">
            {{ formConfig.thresholds.subTitle }}
          </div>
        </v-card-title>
        <v-row>
          <v-col cols="4">
            <div class="form-group">
              <v-text-field
                class="mt-2"
                outlined
                type="number"
                :disabled="!isAdmin"
                label="When Inbox % is below"
                @keypress="onlyNumber"
                min="0"
                :rules="[numeric('Inbox %'), maxLengthNumber(100, 'Inbox %')]"
                v-model="formData.inbox_threshold"
              />
            </div>
          </v-col>
          <v-col cols="4">
            <div class="form-group">
              <v-text-field
                class="mt-2"
                outlined
                type="number"
                :disabled="!isAdmin"
                label="When Spam % is above"
                @keypress="onlyNumber"
                min="0"
                :rules="[numeric('Spam %'), maxLengthNumber(100, 'Spam %')]"
                v-model="formData.spam_threshold"
              />
            </div>
          </v-col>
          <v-col cols="4">
            <div class="form-group">
              <v-text-field
                class="mt-2"
                outlined
                type="number"
                :disabled="!isAdmin"
                label="When Missing % is above"
                @keypress="onlyNumber"
                min="0"
                :rules="[
                  numeric('Missing %'),
                  maxLengthNumber(100, 'Missing %'),
                ]"
                maxlength="100"
                v-model="formData.missing_threshold"
              />
            </div>
          </v-col>
        </v-row>
      </template>
    </card-widget>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { onlyNumber } from "@/utils/common.utils";

import CardWidget from "../widgets/CardWidget.vue";
import { maxLengthNumber, numeric } from "@/validators";

/**
 * THreshold settings form
 */
export default {
  name: "ThresholdSettingForm",
  /**
   * ---------------- Props ------------------
   */
  props: {
    formData: { type: Object, required: true, default: () => ({}) },
  },
  /**
   * ---------------- Components ------------------
   */
  components: {
    CardWidget,
  },
  /**
   * ---------------- Data Properties ------------------
   */
  data() {
    const { accountform } = this.$dictionary.app.accountSettings;
    return {
      formConfig: accountform,
    };
  },
  /**
   * ---------------- Methods ------------------
   */
  methods: {
    onlyNumber,
    numeric,
    maxLengthNumber,
  },
  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    ...mapGetters({
      isAdmin: "auth/isAdmin",
    }),
  },
};
</script>
