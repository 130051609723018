<template>
  <div id="user-profile__form">
    <v-form
      v-model="isFormValid"
      id="user-profile-form"
      @submit.prevent="submitHandler"
      ref="userProfileForm"
      autocomplete="off"
    >
      <card-widget
        :cardTitle="$dictionary.app.userProfile.form.title"
        containerClasses="px-8 py-4"
      >
        <template #cardBody>
          <v-row class="pt-2">
            <v-col cols="6" class="py-1">
              <v-text-field
                name="firstname"
                label="First Name"
                outlined
                class="required"
                :rules="[
                  required('First name'),
                  maxLength(100, 'First Name', false),
                ]"
                v-model="formData.first_name"
              />
            </v-col>
            <v-col cols="6" class="py-1">
              <v-text-field
                name="lastname"
                label="Last Name"
                class="required"
                outlined
                :rules="[
                  required('Last name'),
                  maxLength(100, 'Last Name', false),
                ]"
                v-model="formData.last_name"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="py-1">
              <v-text-field
                outlined
                type="tel"
                name="phone"
                label="Phone"
                persistent-hint
                ref="phoneNumber"
                @keydown="validatePhoneNumber"
                @input="maskPhoneNumber"
                v-model="formData.phone"
                hint="Format: 123-456-7890"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                :rules="phoneRules"
              />
            </v-col>
            <v-col cols="6" class="py-1">
              <v-select
                :items="timezones"
                outlined
                item-text="name"
                item-value="identifier"
                :name="`timezone${_uid}`"
                v-model="formData.time_zone"
                label="Preferred Time Zone"
                autocomplete="new-password"
                :menu-props="{ bottom: true, offsetY: true }"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-6 border-light-grey" />
          <div id="login-information" class="pt-8">
            <h5 class="text-h6 font-weight-large pb-6">
              {{ $dictionary.app.userProfile.form.subtitle }}
            </h5>
            <v-row>
              <v-col md="6" class="pt-1 pb-4">
                <v-text-field
                  name="email"
                  label="Email"
                  disabled
                  readonly
                  outlined
                  v-model="formData.email"
                  :rules="[email, maxLength(255, 'Email', false)]"
                />
              </v-col>
              <v-col md="6" class="pt-1 pb-4">
                <password-input-field
                  isRequired
                  isHintPersistent
                  id="current-password"
                  :password="password"
                  hideDetails="auto"
                  @update-password="password = $event"
                >
                  <template #default>
                    <div id="password__message" class="caption pt-1">
                      Current password is required to confirm any changes.
                      <div>
                        To change password
                        <tooltip
                          #default="{ on, attrs }"
                          name="Change password"
                        >
                          <span
                            v-on="on"
                            v-bind="attrs"
                            @click.stop="toggleDrawer"
                            id="change-password__link"
                            class="blue--text cursor-pointer"
                          >
                            Click Here
                          </span>
                        </tooltip>
                      </div>
                    </div>
                  </template>
                </password-input-field>
              </v-col>
            </v-row>
          </div>
          <loader :loading="isLoading" :size="$appConfig.loader.small" />
        </template>
      </card-widget>
      <v-divider class="mt-8 border-light-grey" />
      <v-card flat class="transparent pt-2">
        <v-card-text class="px-0">
          <v-btn
            type="submit"
            color="primary"
            class="px-4 button font-weight-bold white-btn-text"
            :disabled="!isFormValid"
            :loading="isLoading"
          >
            Save Changes
          </v-btn>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { CardWidget } from "../widgets";
import { PasswordInputField, Tooltip } from "../shared";
import {
  email,
  required,
  maxLength,
  numeric,
  specialCharacters,
} from "@/validators";
import { updateLoggedInUserProfile } from "@/services";
import { pick } from "@/utils/common.utils";

/** Phone number masking seperator */
const PHONE_NUMBER_SEPERATOR = "-";

/**
 * User profile form
 */
export default {
  name: "UserProfileForm",
  /**
   * ---------------- Components ----------------
   */
  components: {
    Tooltip,
    CardWidget,
    PasswordInputField,
  },
  /**
   * ---------------- Props ----------------
   */
  props: {
    isDrawerOpen: {
      type: Boolean,
    },
  },
  /**
   * Custom events emitted by the component
   */
  emits: ["toogle-drawer"],
  /**
   * ---------------- Data properties ----------------
   */
  data() {
    return {
      isFormValid: false,
      formData: {
        first_name: "",
        last_name: "",
        phone: "",
        time_zone: "",
        email: "",
      },
      password: "",
      isLoading: false,
      doesUserExits: false,
      isPasswordInvalid: true,
      phoneRules: [
        (v) =>
          !v ||
          (v && v?.replaceAll(PHONE_NUMBER_SEPERATOR, "").length <= 10) ||
          "Phone number length should be less than 10 digits in length",
        (v) =>
          !v ||
          (v && v?.replaceAll(PHONE_NUMBER_SEPERATOR, "").length == 10) ||
          "Phone number should be of 10 digits in length",
      ],
    };
  },
  watch: {
    /**
     * Sets the formdata on the basis of user profile details in the store
     */
    currentUserProfile(val) {
      this.formData = { ...this.formData, ...val };
      val?.phone && this.maskPhoneNumber(val.phone);
    },
  },
  /**
   * ---------------- Computed properties ----------------
   */
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      timezones: "app/timezones",
      currentUserProfile: "auth/currentUserProfile",
    }),
    /**
     * Updates user profile payload
     */
    userProfilePayload() {
      const props = ["first_name", "last_name", "time_zone", "email"];

      return {
        user: {
          ...pick(this.formData, props),
          phone: this.formData.phone?.replaceAll(PHONE_NUMBER_SEPERATOR, ""),
        },
      };
    },
    /**
     * Reauthenticate payload
     */
    authenticatePayload() {
      return {
        email: this.formData.email,
        password: this.password,
      };
    },
    /**
     * Validation messages
     * @type {Object}
     */
    messages() {
      return this.$dictionary.errors;
    },
  },
  /**
   * ---------------- Methods ----------------
   */
  methods: {
    email,
    required,
    maxLength,
    numeric,
    ...mapActions({
      getCurrentUserDetails: "auth/getCurrentUserDetails",
      reauthenticateUser: "auth/reauthenticateUser",
      setSnackbar: "ui/setSnackbar",
      getCurrentUserProfile: "auth/getCurrentUserProfile",
      getTimezones: "app/getTimezones",
    }),
    /**
     * Toogles right side drawer
     * @emits toogle-drawer
     */
    toggleDrawer() {
      this.$emit("toogle-drawer", !this.isDrawerOpen);
    },
    /**
     * Fetches user details
     */ async getUserDetails() {
      try {
        this.setLoading(true);
        await this.getCurrentUserProfile();
      } catch (error) {
        const message = error?.message ?? this.messages.common.msg;
        this.setSnackbar({
          value: true,
          type: this.$appConfig.snackbar.snackbarTypes.error,
          message,
        });
      } finally {
        this.setLoading(false);
      }
    },
    /**
     * Send a post request to update the user profile
     */ async updateProfile() {
      try {
        this.setLoading(true);

        await this.reauthenticateUser(this.authenticatePayload);
        await updateLoggedInUserProfile(this.userProfilePayload);
        await this.getCurrentUserProfile();

        this.setSnackbar({
          value: true,
          type: this.$appConfig.snackbar.snackbarTypes.success,
          message: this.$dictionary.app.userProfile.form.successMsg,
        });
      } catch (error) {
        let { code, message } = error;
        if (code === "auth/wrong-password") {
          message = this.messages.auth.invalidPassword;
        }

        this.setSnackbar({
          value: true,
          message,
          type: this.$appConfig.snackbar.snackbarTypes.error,
        });
      } finally {
        this.setLoading(false);
      }
    },
    /**
     * maskPhoneNumber
     * returns a string that is in XXX-XXX-XXXX format
     */
    maskPhoneNumber(value) {
      const REGEX = /(\d{0,3})(\d{0,3})(\d{0,4})/;
      var [, FIRST, SECOND, THIRD] = value.replace(/\D/g, "").match(REGEX);

      let maskedNumber = !SECOND
        ? FIRST
        : `${FIRST}-${SECOND}` + (THIRD ? `-${THIRD}` : "");

      this.formData.phone = maskedNumber;
    },
    /**
     * validatePhoneNumber
     * @description validates the phone number on input event
     */
    validatePhoneNumber(event) {
      let charCode = event.which ? event.which : event.keyCode;

      /** Is key entered by the user is special character  */
      const isSpecialCharacter = specialCharacters(event.key);
      if (
        (charCode > 31 && (charCode < 48 || charCode > 57)) ||
        isSpecialCharacter
      ) {
        event.preventDefault();
        return;
      }
    },
    /**
     * Handles the user profile form submition
     */
    async submitHandler() {
      if (!this.isFormValid) {
        return this.$refs.userProfileForm.validate();
      }
      await this.updateProfile();
    },
    /**
     * Sets isLoading value and show the loader
     * @param {Boolean} val New value of the isLoading property
     */
    setLoading(val) {
      this.isLoading = val;
    },
  },
  /**
   * ---------------- Created lifecycle hook ----------------
   */
  async created() {
    this.getUserDetails();
    this.getTimezones();
  },
};
</script>
