<template>
  <right-side-bar
    fixed
    divider
    prepend
    width="30%"
    :title="'Add User'"
    drawerClasses="pa-8"
    :is-right-drawer-open="addUserDrawer"
    @toggle-right-bar="$emit('close-nav-drawer', $event)"
  >
    <v-form
      id="add-user-form"
      ref="form"
      v-model="isFormValid"
      @submit.prevent="handleSubmit"
      @keyup.enter="handleSubmit"
      autocomplete="off"
    >
      <v-row>
        <v-col cols="12" class="py-0">
          <div class="pb-6">
            <label for="user-email-address" class="overline font-weight-large">
              USER INFORMATION
            </label>
          </div>
          <div>
            <v-text-field
              id="user-email-address"
              v-model="user.email"
              :rules="[
                required('Email address'),
                email,
                maxLength(50, 'Email'),
              ]"
              label="Email Address"
              class="required"
              outlined
            />

            <template v-if="!userExists">
              <password-input-field
                isRequired
                isHintPersistent
                label="Temporary Password"
                :rules="[
                  isNewPasswordValid,
                  maxLength(50, 'Temporary Password'),
                ]"
                :password="formData.password"
                @update-password="formData.password = $event"/>

              <template v-for="{ text, isValid } in passwordRules">
                <v-row :key="text" no-gutters>
                  <v-col cols="1" class="d-flex align-center">
                    <v-icon :color="getIconColor({ text, isValid })" small>
                      {{ isValid ? "mdi-check-circle" : "mdi-close-circle" }}
                    </v-icon>
                  </v-col>
                  <v-col
                    cols="11"
                    :class="!isValid && isNewPasswordDirty ? 'red--text' : ''"
                    class="caption py-1"
                  >
                    {{ text }}
                  </v-col>
                </v-row>
              </template>
              <br />
              <password-input-field
                isRequired
                isHintPersistent
                label="Confirm Temporary Password"
                :rules="[
                  validateConfirmPassword,
                  isConfirmPasswordSame,
                  maxLength(50, 'Confirm Temporary Password'),
                ]"
                :password="formData.confirmPassword"
                @update-password="formData.confirmPassword = $event"
            /></template>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="py-0">
          <v-divider class="mb-4 mt-2 border-light-grey" />
          <div class="mb-6">
            <label class="overline font-weight-large" for="user-role-dropdown">
              USER ACCESS
            </label>
          </div>
          <v-autocomplete
            outlined
            label="Role"
            :items="roles"
            :item-text="role_name"
            id="user-role-dropdown"
            class="required"
            autocomplete="off"
            v-model="user.role"
            :rules="[required('Role')]"
            :menu-props="{ bottom: true }"
          >
            <template #item="{ item, on, attrs }">
              <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-content
                  class="body-2 text-black--80 font--primary"
                >
                  {{ item | capitalize }}
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
          <v-divider class="mb-6 border-light-grey" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            type="submit"
            color="primary"
            :loading="isLoading"
            :disabled="!isFormValid"
            class="px-4 button font-weight-bold white-btn-text"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </right-side-bar>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { PasswordValidatorMixin } from "@/mixins";
import { PasswordInputField } from "../shared";
import {
  createUser,
  createUserProfile,
  updateUserDetails,
  fetchSignInMethodsForEmail,
  getUsers,
} from "@/services";
import { capitalize } from "@/filters";
import {
  required,
  email,
  letterOnly,
  maxLength,
} from "@/validators/form-valdators";
import { RESPONSE_CODES, ROLES, TABLE_PER_PAGES_OPTIONS } from "@/constants";
import { isEmpty, isResponseCode, pick } from "@/utils/common.utils";
import { RightSideBar } from "@/components/drawers";

/**
 * Add or update user form
 * @description Creates  and updates the user
 */
export default {
  name: "AddUserForm",
  /**
   * -------------- Components -------------
   */
  components: {
    RightSideBar,
    PasswordInputField,
  },
  /**
   * -------------- Mixins -------------
   */
  mixins: [PasswordValidatorMixin],
  /**
   * -------------- Props Properties -------------
   */
  props: {
    addUserDrawer: {
      type: Boolean,
      default: false,
    },
    roles: {
      type: Array,
      required: true,
    },
  },
  /**
   * -------------- Props Properties -------------
   */
  data() {
    return {
      isDrawerOpen: false,
      isFormValid: false,
      showPassword: false,
      userType: "existing",
      user: {
        id: "",
        email: "",
        password: "",
        confirmPassword: "",
        role: ROLES.user,
        user_id: undefined,
      },
      userExists: true,
      isLoading: false,
      profileExists: false,
    };
  },
  /**
   * ---------- Watch properties ------------
   */
  watch: {
    /**
     * Watches user email changes and then resets the view
     */
    "user.email"(val, oldVal) {
      if (val !== oldVal && !this.userExists) {
        this.userExists = true;
      }
    },
  },

  /**
   * ----------Computed properties-------
   */
  computed: {
    ...mapGetters({
      selectedAccount: "ui/selectedAccount",
      accountId: "ui/selectedAccountId",
    }),
    /**
     * Error messages
     * @description Fetched from the app dictionary
     */
    displayMessages() {
      const { snackbarTypes } = this.$appConfig.snackbar;
      return { ...this.$dictionary.app.users.errorMessages, snackbarTypes };
    },
    /**
     * Create user request payload
     */
    createUserPayload() {
      const { email, role } = this.user;

      return {
        email,
        role: role?.toLowerCase(),
        ...pick(this.formData, ["password"]),
        account_id: this.selectedAccount?.account_id,
      };
    },
    /**
     * Update user request payload
     */
    updateUserPayload() {
      const { email, role } = this.user;
      return {
        user: {
          email,
          role: role?.toLowerCase(),
        },
      };
    },
  },
  /**
   * -----------Emit Events---------------
   */
  emits: ["close-nav-drawer"],

  /**
   * -------------Methods -----------------
   */
  methods: {
    required,
    email,
    maxLength,
    letterOnly,
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
    }),

    /**
     * Close Nav drawer
     * @event close-nav-drawer
     */
    closeNavDrawer() {
      this.$refs.form.reset();
      this.$emit("close-nav-drawer");
    },
    /**
     * Verifies that user profile is present in InboxAware database or not
     * @returns {Boolean} Is user profile present or not.
     */
    async verifyInboxAwareUserProfile(email) {
      try {
        this.isLoading = true;
        const [per_page] = TABLE_PER_PAGES_OPTIONS;
        const payload = {
          "q[email_cont]": email,
          per_page,
          page: 1,
          account_id: this.accountId,
        };

        const { data } = await getUsers(payload);
        this.profileExists = !isEmpty(data)
          ? data.some(
              (user) => user.email?.toLowerCase() === email?.toLowerCase()
            )
          : false;

        return this.profileExists;
      } catch (error) {
        this.isLoading = false;
        return Promise.reject(error);
      }
    },
    /**
     * Capitalize role name
     */
    role_name: (item) => capitalize(item),
    /**
     * Create user
     * @param {Object} params user requets payload details
     * @returns {Promise}
     */
    async createUserProfile({ email, password, role, account_id }) {
      if (!this.userExists && !password) return;

      try {
        this.isLoading = true;
        let data, message;
        const payload = { user: { email, role }, account_id };

        await this.verifyInboxAwareUserProfile(email);

        // Creates user profile in database if it is doesnot exists
        !this.profileExists && (data = await createUserProfile(payload));

        // Create new user account in GIP
        if (!this.userExists && password) {
          await createUser(email, password);
        } else if (this.userExists && this.profileExists) {
          // Updates the roles of already existed user profile in the database
          data = await updateUserDetails(account_id, 1, this.updateUserPayload);
        }

        this.showSuccessMessage(message, email, data);
        this.formData.password = "";
        this.$emit("get-users");
      } finally {
        this.isLoading = false;
        this.closeNavDrawer();
      }
    },
    /**
     * Show user created and updated success message to the user in snackbar
     */
    showSuccessMessage(message, email, data) {
      const { createdMsg, updatedMsg } = this.$dictionary.app.users;

      if (isResponseCode(RESPONSE_CODES.created, data?.status)) {
        message = createdMsg.replace(/<<name>>/gi, email);
      } else if (isResponseCode(RESPONSE_CODES.success, data?.status)) {
        message = updatedMsg.replace(/<<name>>/gi, email);
      }

      this.setSnackbar({
        value: true,
        message,
        type: this.displayMessages.snackbarTypes.success,
      });
    },
    /**
     * Create a user profile record in the database if the user is already present in the Gip
     */
    async createUserRecord({ response }, payload) {
      let data;
      const { createdMsg } = this.$dictionary.app.users;

      const userExistsInGip =
        this.userExists && response?.status === RESPONSE_CODES.notFound;
      if (userExistsInGip) data = await createUserProfile(payload);

      if (isResponseCode(RESPONSE_CODES.created, data?.status)) {
        this.setSnackbar({
          value: true,
          message: createdMsg.replace(/<<name>>/gi, payload?.user?.email ?? ""),
          type: this.displayMessages.snackbarTypes.success,
        });
      }
    },
    /**
     * Create or Update user
     */
    async handleSubmit() {
      this.isLoading = true;

      try {
        await this.checkUserAvailability();

        // User Creation
        await this.createUserProfile(this.createUserPayload);
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: error.message,
          type: this.displayMessages.snackbarTypes.error,
        });
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Checks if user exists in the gip and then adds the user to the selected account
     */
    async checkUserAvailability() {
      const userSignInMethod = await fetchSignInMethodsForEmail(
        this.user.email
      );
      this.userExists = !isEmpty(userSignInMethod);

      return this.userExists;
    },
  },
};
</script>
