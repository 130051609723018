<template>
  <div id="isp-filter__form">
    <v-form v-model="isFormValid" ref="ispForm" @submit.prevent="submitForm">
      <div class="" id="isp-filter-form-content">
        <div class="form-group">
          <div class="text-left mb-6">
            <label
              for="isp-ip-address-filter"
              class="overline text--primary font-weight-large"
            >
              IP Address
            </label>
          </div>
          <v-autocomplete
            outlined
            multiple
            id="isp-ip-address-filter"
            label="IP Address"
            :items="sendingIps"
            small-chips
            v-model="formData.sending_ips"
            item-text="ip_address"
            item-value="ip_address"
            no-data-text="No ip address found"
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                class="filter-light-green-chip body-2 text--primary"
                @click="data.select"
                @click:close="removeSelectedIpAddress(data.item.ip_address)"
              >
                {{ data.item.ip_address }}
              </v-chip>
            </template>
            <template v-slot:item="{ on, attrs, item }">
              <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                <v-list-item-action class="mr-4">
                  <v-checkbox :input-value="active" />
                </v-list-item-action>
                <v-list-item-content class="body-2 text--primary">
                  {{ item.ip_address }}
                  <v-spacer />
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </div>

        <v-divider class="border-light-grey" />
        <div class="form-group">
          <div class="text-left mb-6 mt-3">
            <label
              for="ips-mailbox-providers-filter"
              class="overline text--primary font-weight-large"
            >
              Mailbox Provider
            </label>
          </div>
          <v-autocomplete
            outlined
            small-chips
            multiple
            id="ips-mailbox-providers-filter"
            label="Mailbox Provider"
            :items="mailboxProviders"
            v-model="formData.seed_domains"
            item-text="name"
            item-value="seed_domains"
            no-data-text="No mailbox providers found"
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                class="filter-light-green-chip body-2 text--primary"
                @click:close="
                  removeSelectedMailboxProviders(data.item.seed_domains)
                "
              >
                {{ data.item.name }}
              </v-chip>
            </template>
            <template v-slot:item="{ on, attrs, item }">
              <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                <v-list-item-action class="mr-4">
                  <v-checkbox :input-value="active" />
                </v-list-item-action>
                <v-list-item-content class="body-2 text--primary">
                  {{ item.name }}
                  <v-spacer></v-spacer>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </div>
        <v-divider class="border-light-grey" />
        <filter-form-buttons
          :reset-form="resetForm"
          :is-form-valid="isFormValid"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { requiredMultiple } from "@/validators";
import cloneDeep from "lodash/cloneDeep";
import { FilterFormButtons } from "../shared";

/**
 * Isp filter forum
 */
export default {
  name: "IspFilterForm",
  /**
   * ---------------- Props ------------------
   */
  props: {
    setFilters: {
      type: Function,
      required: true,
    },
    sendingIps: {
      type: Array,
      required: true,
    },
    getSendingIPs: {
      type: Function,
      required: true,
    },
  },
  /**
   * -------------- Components --------------
   */
  components: {
    FilterFormButtons,
  },
  /**
   * ---------------- Custome events ------------------
   */
  emits: ["apply-filters", "remove-filters"],
  /**
   * ---------------- Data properties ------------------
   */
  data() {
    return {
      isFormValid: false,
      formData: { sending_ips: [], seed_domains: [] },
    };
  },
  /**
   * ---------------- Methods ------------------
   */
  methods: {
    ...mapActions({
      getMailboxProviders: "account/getMailboxProviders",
    }),
    requiredMultiple,
    /**
     * Resets the isp filter form
     */
    resetForm() {
      this.$refs.ispForm.reset();
      this.setFilters({});
      this.$emit("remove-filters");
    },
    /**
     * Submits the isp filter form
     * @emits apply-filters
     */
    submitForm() {
      if (!this.isFormValid) {
        this.$refs.ispForm.validate();
      } else {
        this.$emit(
          "apply-filters",
          this.ispFilterReqData,
          cloneDeep(this.formData)
        );
      }
    },
    /**
     * Removes the selected mailbox provider sedd
     * @param {String} seedDomain Mailbox provider seed domain to be removed
     */
    removeSelectedMailboxProviders(seedDomain) {
      const index = this.formData.seed_domains.indexOf(seedDomain);
      if (index >= 0) this.formData.seed_domains.splice(index, 1);
    },
    /**
     * Removes the filter ip address
     * @param {String} sendingIp Selected seninf ip to be removed from the filter
     */
    removeSelectedIpAddress(sendingIp) {
      const index = this.formData.sending_ips.indexOf(sendingIp);
      if (index >= 0) this.formData.sending_ips.splice(index, 1);
    },
  },
  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    ...mapGetters({
      selectedAccount: "ui/selectedAccount",
      mailboxProviders: "account/mailboxProviders",
      mailboxProviderSettings: "settings/mailboxProvider",
    }),
    /**
     * Isp filter data
     * @type {Object}
     */
    ispFilterReqData() {
      const { seed_domains, sending_ips } = this.formData;
      return {
        seed_domains: seed_domains?.join(","),
        sending_ips: sending_ips?.join(","),
      };
    },
  },
  /**
|--------------------------------------------------
| Created lifecycle hook
|--------------------------------------------------
*/
  async created() {
    const params = { account_id: this.selectedAccount?.account_id };
    await this.getMailboxProviders(params);
  },
  beforeMount() {
    this.formData = { ...cloneDeep(this.mailboxProviderSettings?.filters) };
  },
};
</script>
