<template>
  <div id="account-settings-form">
    <v-row class="d-flex justify-center" no-gutters>
      <v-col cols="12" md="10" lg="10">
        <v-card
          class="transparent my-2 mx-2 mt-3"
          id="account-setting-form"
          flat
        >
          <v-form
            v-model="isFormValid"
            ref="accountForm"
            @submit.prevent="updateAccountDetails"
          >
            <card-widget>
              <template #cardBody>
                <!---------------------------- ACCOUNT SETTINGS FORM STARTS ---------------------------->
                <v-row class="pb-8" no-gutters>
                  <v-col cols="12" md="12">
                    <h6 class="text-h6 font-weight-large">
                      {{ formConfig.title }}
                    </h6>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col md="6" cols="12" class="pt-0">
                    <!-- Todo add async validation to validate the account name user have entered -->
                    <v-text-field
                      v-model="accountDetails.name"
                      label="Account Name"
                      class="required"
                      outlined
                      flat
                      :disabled="!isAdmin"
                      :rules="[
                        required('Account Name'),
                        maxLength(255, 'Account Name'),
                      ]"
                  /></v-col>
                  <v-col md="6" cols="12" class="pt-0">
                    <v-text-field
                      v-model="accountDetails.id"
                      label="Account ID"
                      class="required"
                      outlined
                      flat
                      disabled
                      hint="Enter the ID that your email service provider has assigned to your account."
                      persistent-hint
                      maxlength="255"
                      :rules="[required('Account ID')]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col md="6" cols="12" class="pt-0">
                    <v-text-field
                      v-model="accountDetails.address_line_1"
                      label="Address Line 1"
                      outlined
                      :disabled="!isAdmin"
                      :rules="[maxLength(255, 'Address Line 1', false)]"
                    />
                  </v-col>
                  <v-col md="6" cols="12" class="pt-0">
                    <v-text-field
                      v-model="accountDetails.address_line_2"
                      label="Address Line 2"
                      outlined
                      :disabled="!isAdmin"
                      :rules="[maxLength(255, 'Address Line 2', false)]"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="6" cols="12" class="pt-0">
                    <v-autocomplete
                      :items="countries"
                      v-model="accountDetails.country"
                      item-text="label"
                      item-value="value"
                      label="Country"
                      :loading="countriesLoading"
                      :disabled="!isAdmin"
                      no-data-text="No countries available"
                      @change="getCountryStates"
                      outlined
                    />
                  </v-col>
                  <v-col md="6" cols="12" class="pt-0">
                    <v-autocomplete
                      :items="states"
                      item-text="label"
                      item-value="value"
                      v-model="accountDetails.state"
                      label="State/Province"
                      no-data-text="No state/province available"
                      outlined
                      :disabled="areStatesLoading || !isAdmin"
                      :loading="areStatesLoading"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="6" cols="12" class="py-0">
                    <v-text-field
                      v-model="accountDetails.city"
                      label="City"
                      outlined
                      :disabled="!isAdmin"
                      :rules="[maxLength(255, 'City', false)]"
                    />
                  </v-col>
                  <v-col md="6" cols="12" class="py-0">
                    <v-text-field
                      v-model="accountDetails.zipcode"
                      label="Zip/Postal Code"
                      outlined
                      :disabled="!isAdmin"
                      max="100"
                      :rules="[
                        maxLength(255, 'Zip/Postal Code', false),
                        alphaNumeric('Zip/Postal Code'),
                      ]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <!---------------------------- ACCOUNT SETTINGS FORM ENDS ---------------------------->
              </template>
            </card-widget>
            <v-divider class="mt-8 mb-6 border-light-grey" />
            <v-row no-gutters class="d-flex justify-start" v-if="isAdmin">
              <v-col cols="12">
                <v-btn
                  type="submit"
                  id="save-account-settings"
                  medium
                  color="primary"
                  :disabled="!isFormValid"
                  class="px-4 button font-weight-bold white-btn-text"
                >
                  Save Changes
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <loader :loading="isLoading" :size="$appConfig.loader.small" />
        </v-card>
      </v-col>
    </v-row>
    <snackbar
      :snackbarValue="snackbar.value"
      :snackBarType="snackbar.type"
      @close="snackbar.value = $event"
    >
      {{ snackbar.msg }}
    </snackbar>
  </div>
</template>

<script>
import clone from "lodash/clone";
import { createAccount, getAccount } from "@/services";
import { mapGetters, mapActions } from "vuex";
import { CardWidget } from "@/components/widgets";
import { isResponseCode } from "@/utils/common.utils";
import { RESPONSE_CODES } from "@/constants";
import {
  numeric,
  required,
  maxLength,
  alphaNumeric,
  maxLengthNumber,
  minLengthNumber,
} from "@/validators/form-valdators";

/**
 * Account settings tab form
 * @todo Add async validation to validate the account name user have entered in the account name textfield
 */
export default {
  name: "AccountSettingsForm",
  /**
   * ---------------- Components ------------------
   */
  components: {
    CardWidget,
  },
  /**
   * ---------------- Data properties ------------------
   */
  data() {
    const { accountform } = this.$dictionary.app.accountSettings;
    return {
      isFormValid: false,
      timeZones: [],
      cities: [],
      accountDetails: {
        name: "",
        company_name: "",
        website: "",
        time_zone: "",
        address_line_1: "",
        address_line_2: "",
        zipcode: "",
        city: "",
        state: "",
        country: "",
        id: "",
        preferredAccount: "",
        inbox_threshold: "0",
        spam_threshold: "0",
        missing_threshold: "0",
      },
      snackbar: {
        value: false,
        msg: "",
        type: this.$appConfig.snackbar.snackbarTypes.error,
      },
      formConfig: accountform,
      areStatesLoading: false,
      isLoading: false,
      countriesLoading: false,
    };
  },
  watch: {
    async "selectedAccount.account_id"(newVal, oldVal) {
      const account_id = newVal || oldVal;
      await this.assignAccountDetails({ account_id });
    },
  },
  /**
   * ---------------- Methods ------------------
   */
  methods: {
    required,
    maxLength,
    maxLengthNumber,
    numeric,
    alphaNumeric,
    minLengthNumber,
    ...mapActions({
      getCountries: "app/getCountries",
      getStates: "app/getStates",
    }),
    /**
     * Fetches list of countries
     */
    async fetchCountries() {
      try {
        this.countriesLoading = true;
        this.getCountries();
      } finally {
        this.countriesLoading = false;
      }
    },
    /**
     * Creates or update the account details
     */
    async updateAccountDetails() {
      try {
        if (!this.isFormValid) {
          return this.$refs.accountForm.validate();
        }
        this.setIsLoading(true);

        const { status } = await createAccount(this.payload);
        /** To fetch the updated account details */
        await this.assignAccountDetails({
          account_id: this.selectedAccount.account_id,
        });

        if (isResponseCode(status, RESPONSE_CODES.success)) {
          this.setSnackbar(
            this.snackbarTypes.success,
            this.formConfig.successMsg,
            true
          );
        }
      } catch (error) {
        !this.storeSnackbar.value &&
          this.setSnackbar(this.snackbarTypes.error, error?.message, true);
      } finally {
        this.setIsLoading(false);
      }
    },
    setIsLoading(val) {
      this.isLoading = val;
    },
    /**
     * Fetched states of a country
     * @param {String} country Country whose states are to be fetched
     */
    async getCountryStates(country) {
      this.areStatesLoading = true;
      await this.getStates(country);
      this.areStatesLoading = false;

      this.accountDetails.state = "";
    },
    /**
     * Set snackbar messages
     */
    setSnackbar(type, msg, value) {
      this.snackbar = { type, msg, value };
    },
    /**
     * Fetches account details and spread them in the form
     */
    async assignAccountDetails({ account_id }) {
      try {
        this.setIsLoading(true);

        const { data } = await getAccount({ account_id });
        this.accountDetails = data;
      } finally {
        this.setIsLoading(false);
      }
    },
    /**
     * Get threshold settings of the account
     */
    getThreshold: (threshold) => (threshold ? String(threshold) : "0"),
  },
  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    ...mapGetters({
      countries: "app/countries",
      states: "app/states",
      selectedAccount: "ui/selectedAccount",
      storeSnackbar: "ui/snackbar",
      isAdmin: "auth/isAdmin",
    }),
    /**
     * Create or update account settings request payload
     * @type {Object}
     */
    payload() {
      const {
        inbox_threshold,
        spam_threshold,
        missing_threshold,
        ...formData
      } = clone(this.accountDetails);

      return {
        ...formData,
        inbox_threshold: this.getThreshold(inbox_threshold),
        spam_threshold: this.getThreshold(spam_threshold),
        missing_threshold: this.getThreshold(missing_threshold),
      };
    },

    /**
     * Snackbar types
     */
    snackbarTypes() {
      return this.$appConfig.snackbar.snackbarTypes;
    },
  },
  /**
   * ---------------- Created lifecycle method ------------------
   */
  async created() {
    this.setIsLoading(true);
    this.fetchCountries();
    this.setIsLoading(false);

    this.selectedAccount.account_id &&
      this.assignAccountDetails({
        account_id: this.selectedAccount.account_id,
      });
  },
};
</script>
