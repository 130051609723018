<template>
  <div id="change-password__form">
    <div class="text--darken-1 body-2 mt-2 mb-3">
      {{ $dictionary.app.userProfile.resetPassword.subtitle }}
    </div>
    <v-form
      ref="changePasswordForm"
      v-model="isFormValid"
      @submit.prevent="submitHandler"
    >
      <v-row>
        <v-col cols="12">
          <password-input-field
            isRequired
            isHintPersistent
            label="Current password"
            :password="formData.currentPassword"
            @update-password="updatePassword($event, 'currentPassword')"
            hint="We need your current password to confirm your changes"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-1 pb-1">
          <password-input-field
            isRequired
            label="New password"
            :rules="[isNewPasswordValid]"
            :password="formData.password"
            @update-password="updatePassword($event, 'password')"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="py-1 pt-4 pb-1">
          <password-input-field
            isRequired
            isHintPersistent
            hideDetails="auto"
            label="Confirm password"
            :password="formData.confirmPassword"
            :rules="[validateConfirmPassword, isConfirmPasswordSame]"
            @update-password="updatePassword($event, 'confirmPassword')"
          />
        </v-col>
      </v-row>
      <template v-for="{ text, isValid } in passwordRules">
        <v-row :key="text" no-gutters>
          <v-col cols="1" class="d-flex align-center">
            <v-icon :color="getIconColor({ text, isValid })" small>
              {{ isValid ? "mdi-check-circle" : "mdi-close-circle" }}
            </v-icon>
          </v-col>
          <v-col
            cols="11"
            :class="!isValid && isNewPasswordDirty ? 'red--text' : ''"
            class="caption py-1 text--secondary"
          >
            {{ text }}
          </v-col>
        </v-row>
      </template>
      <v-row>
        <v-col cols="12" class="pt-9">
          <v-btn
            :loading="isLoading"
            :disabled="!isFormValid"
            class="px-4 button submit-change-password font-weight-bold white-btn-text"
            type="submit"
            color="primary"
          >
            Change Password
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { resetPassword } from "@/services";
import { mapActions, mapGetters } from "vuex";
import { PasswordInputField } from "../shared";
import { PasswordValidatorMixin } from "@/mixins";
import { AUTH_GIP_ERROR_CODES } from "@/constants";

/**
 * Chnage password form
 */
export default {
  name: "ChangePassword",
  /**
   * -------------- Mixins --------------
   */
  mixins: [PasswordValidatorMixin],
  /**
   * -------------- Components --------------
   */
  components: {
    PasswordInputField,
  },
  /**
   * Custom events emitted by the component
   */
  emits: ["toogle-drawer"],
  /**
   * -------------- Data Properties -------------
   */
  data() {
    return {
      isFormValid: false,
      formData: {
        currentPassword: "",
        password: "",
        confirmPassword: "",
      },
      isLoading: false,
    };
  },
  /**
   * -------------- Computed Properties -------------
   */
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    /**
     * Reauthenticate user payload
     */
    reauthenticateUserPayload() {
      return {
        email: this.currentUser?.email,
        password: this.formData.currentPassword,
      };
    },
    /**
     * Error messages
     * @description Fetched from the app dictionary
     */
    displayMessages() {
      const {
        errorMessages,
        successMessages,
      } = this.$dictionary.app.userProfile.resetPassword;
      return { ...successMessages, ...errorMessages };
    },
  },
  /**
   * -------------- Methods --------------
   */
  methods: {
    ...mapActions({
      reauthenticateUser: "auth/reauthenticateUser",
      setSnackbar: "ui/setSnackbar",
    }),
    /**
     * Consumes the service and resets the user password
     */
    async resetPassword() {
      try {
        this.isLoading = true;

        // Reauthenticates the user
        await this.reauthenticateUser(this.reauthenticateUserPayload);
        // Resets the user password
        await resetPassword(this.formData.password);

        this.$emit("toogle-drawer", false);
        this.$refs.changePasswordForm.reset();
        this.setSnackbar({
          value: true,
          message: this.displayMessages.passwordChangedSuccessfully,
          type: this.$appConfig.snackbar.snackbarTypes.success,
        });
      } catch (error) {
        let { code, message } = error;
        if (code === AUTH_GIP_ERROR_CODES.wrongPassword) {
          message = this.displayMessages.currentPasswordInvalid;
        }

        this.setSnackbar({
          value: true,
          message,
          type: this.$appConfig.snackbar.snackbarTypes.error,
        });
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Handles password change form handler
     */
    submitHandler() {
      if (!this.isFormValid) {
        return this.$refs.changePasswordForm.validate();
      }
      this.resetPassword();
    },
    /**
     * Updates the password fields value
     * @param {String} value Updated value of the password field
     * @param {String} prop Model property name of password field to be updated
     */
    updatePassword(value, prop) {
      if (!prop) return;
      this.formData[prop] = value;
    },
  },
};
</script>
