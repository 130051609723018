var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"isp-filter__form"}},[_c('v-form',{ref:"ispForm",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('div',{attrs:{"id":"isp-filter-form-content"}},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"text-left mb-6"},[_c('label',{staticClass:"overline text--primary font-weight-large",attrs:{"for":"isp-ip-address-filter"}},[_vm._v(" IP Address ")])]),_c('v-autocomplete',{attrs:{"outlined":"","multiple":"","id":"isp-ip-address-filter","label":"IP Address","items":_vm.sendingIps,"small-chips":"","item-text":"ip_address","item-value":"ip_address","no-data-text":"No ip address found"},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"filter-light-green-chip body-2 text--primary",attrs:{"input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){return _vm.removeSelectedIpAddress(data.item.ip_address)}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.ip_address)+" ")])]}},{key:"item",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var item = ref.item;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mr-4"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',{staticClass:"body-2 text--primary"},[_vm._v(" "+_vm._s(item.ip_address)+" "),_c('v-spacer')],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.formData.sending_ips),callback:function ($$v) {_vm.$set(_vm.formData, "sending_ips", $$v)},expression:"formData.sending_ips"}})],1),_c('v-divider',{staticClass:"border-light-grey"}),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"text-left mb-6 mt-3"},[_c('label',{staticClass:"overline text--primary font-weight-large",attrs:{"for":"ips-mailbox-providers-filter"}},[_vm._v(" Mailbox Provider ")])]),_c('v-autocomplete',{attrs:{"outlined":"","small-chips":"","multiple":"","id":"ips-mailbox-providers-filter","label":"Mailbox Provider","items":_vm.mailboxProviders,"item-text":"name","item-value":"seed_domains","no-data-text":"No mailbox providers found"},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"filter-light-green-chip body-2 text--primary",attrs:{"input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){return _vm.removeSelectedMailboxProviders(data.item.seed_domains)}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.name)+" ")])]}},{key:"item",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var item = ref.item;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mr-4"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',{staticClass:"body-2 text--primary"},[_vm._v(" "+_vm._s(item.name)+" "),_c('v-spacer')],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.formData.seed_domains),callback:function ($$v) {_vm.$set(_vm.formData, "seed_domains", $$v)},expression:"formData.seed_domains"}})],1),_c('v-divider',{staticClass:"border-light-grey"}),_c('filter-form-buttons',{attrs:{"reset-form":_vm.resetForm,"is-form-valid":_vm.isFormValid}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }