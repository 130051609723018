<template>
  <v-navigation-drawer
    fixed
    right
    temporary
    width="30%"
    v-model="isDrawerOpen"
    id="edit-permission-form"
    class="right-nav__drawer pa-8"
  >
    <div class="d-flex justify-space-between flex-wrap">
      <h5 class="text-h5 text--primary font--secondary font-weight-large">
        Edit Permission
      </h5>
      <tooltip #default="{ on, attrs }" name="Close">
        <v-btn v-on="on" v-bind="attrs" icon>
          <v-icon class="text-dark-grey" @click.stop="closeNavDrawer">
            mdi-close
          </v-icon>
        </v-btn>
      </tooltip>
    </div>
    <v-divider class="my-4 border-light-grey" />
    <v-form ref="form" v-model="isFormValid" @submit.prevent="handleSubmit">
      <v-row no-gutters id="user-permissions-wrapper">
        <v-col cols="12">
          <div class="overline pb-4 font-weight-large">USER INFORMATION</div>

          <div class="caption text--secondary pb-1">Email Address</div>
          <div class="body-2 pb-6">{{ user.email }}</div>

          <div
            class="caption text--secondary pb-1 text-capitalize"
            v-if="user.first_name"
          >
            Name
          </div>
          <div class="body-2">{{ firstName }}</div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-divider class="mb-6 border-light-grey" />
          <div class="mb-4 overline text--primary">
            <label
              class="overline font-weight-large"
              for="user-permission-roles"
            >
              USER ACCESS
            </label>
          </div>

          <v-select
            id="user-permission-roles"
            v-model="user.role"
            :items="roles"
            :rules="[required('Role')]"
            label="Role"
            :menu-props="{ bottom: true }"
            hide-details
            outlined
            class="required"
          >
            <template #item="{ item, on, attrs }">
              <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-content
                  class="body-2 text-black--80 font--primary"
                >
                  {{ item | capitalize }}
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-divider class="mb-6 mt-5 border-light-grey" />
      <v-row>
        <v-col>
          <v-btn
            type="submit"
            color="primary"
            :loading="isLoading"
            :disabled="!isFormValid"
            class="px-4 font-weight-bold"
          >
            Save Changes
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "@/validators/form-valdators";
import { updateUser } from "@/services";
import { capitalize } from "@/filters";
import { Tooltip } from "../shared";
import { ROLES } from "@/constants";

export default {
  name: "EditPermissionForm",
  components: {
    Tooltip,
  },
  /**
   * ------------Props attribute -------------
   */
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: false,
    },
    roles: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      default: () => {
        return {
          first_name: "",
          last_name: "",
          email: "",
          role: ROLES.user,
          user_id: undefined,
        };
      },
    },
  },
  /**
   * -------------------Data attributes -------------------
   */
  data() {
    return {
      isFormValid: false,
      isLoading: false,
    };
  },

  /**
   * -----------Emit Events---------------
   */
  emits: ["close-nav-drawer"],

  /**
   * ----------Computed properties-------
   */
  computed: {
    ...mapGetters({
      selectedAccount: "ui/selectedAccount",
      account_id: "ui/selectedAccountId",
    }),
    /**
     * firstName
     * @description Computes the first name of the user
     */
    firstName() {
      return `${this.user.first_name ?? ""} ${this.user.last_name ?? ""}`;
    },
    /**
     * Update user permissions rrequest payload
     * @type {Object}
     */
    payload() {
      const { role, id, email } = this.user;

      return {
        user: { role: role.toLowerCase(), email },
        account_id: this.account_id,
        id,
      };
    },
  },
  /**
   * -------------Methods -----------------
   */
  methods: {
    required,
    capitalize,
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
    }),
    /**
     * Close Nav drawer
     * @event close-nav-drawer
     */
    closeNavDrawer() {
      this.$refs.form.reset();
      this.$emit("close-nav-drawer");
    },
    /**
     * Update user permission
     */
    async handleSubmit() {
      this.isLoading = true;
      try {
        await updateUser(this.payload);
        this.closeNavDrawer();
        this.$emit("get-users");

        this.setSnackbar({
          value: true,
          message: this.$dictionary.app.users.updatedMsg.replace(
            /<<name>>/gi,
            this.user.first_name || this.user.email
          ),
          type: this.$appConfig.snackbar.snackbarTypes.success,
        });
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: error.message,
          type: this.$appConfig.snackbar.snackbarTypes.success,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
