var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('right-side-bar',{attrs:{"fixed":"","divider":"","prepend":"","width":"30%","title":'Add User',"drawerClasses":"pa-8","is-right-drawer-open":_vm.addUserDrawer},on:{"toggle-right-bar":function($event){return _vm.$emit('close-nav-drawer', $event)}}},[_c('v-form',{ref:"form",attrs:{"id":"add-user-form","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit($event)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"pb-6"},[_c('label',{staticClass:"overline font-weight-large",attrs:{"for":"user-email-address"}},[_vm._v(" USER INFORMATION ")])]),_c('div',[_c('v-text-field',{staticClass:"required",attrs:{"id":"user-email-address","rules":[
              _vm.required('Email address'),
              _vm.email,
              _vm.maxLength(50, 'Email') ],"label":"Email Address","outlined":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),(!_vm.userExists)?[_c('password-input-field',{attrs:{"isRequired":"","isHintPersistent":"","label":"Temporary Password","rules":[
                _vm.isNewPasswordValid,
                _vm.maxLength(50, 'Temporary Password') ],"password":_vm.formData.password},on:{"update-password":function($event){_vm.formData.password = $event}}}),_vm._l((_vm.passwordRules),function(ref){
              var text = ref.text;
              var isValid = ref.isValid;
return [_c('v-row',{key:text,attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"color":_vm.getIconColor({ text: text, isValid: isValid }),"small":""}},[_vm._v(" "+_vm._s(isValid ? "mdi-check-circle" : "mdi-close-circle")+" ")])],1),_c('v-col',{staticClass:"caption py-1",class:!isValid && _vm.isNewPasswordDirty ? 'red--text' : '',attrs:{"cols":"11"}},[_vm._v(" "+_vm._s(text)+" ")])],1)]}),_c('br'),_c('password-input-field',{attrs:{"isRequired":"","isHintPersistent":"","label":"Confirm Temporary Password","rules":[
                _vm.validateConfirmPassword,
                _vm.isConfirmPasswordSame,
                _vm.maxLength(50, 'Confirm Temporary Password') ],"password":_vm.formData.confirmPassword},on:{"update-password":function($event){_vm.formData.confirmPassword = $event}}})]:_vm._e()],2)])],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"mb-4 mt-2 border-light-grey"}),_c('div',{staticClass:"mb-6"},[_c('label',{staticClass:"overline font-weight-large",attrs:{"for":"user-role-dropdown"}},[_vm._v(" USER ACCESS ")])]),_c('v-autocomplete',{staticClass:"required",attrs:{"outlined":"","label":"Role","items":_vm.roles,"item-text":_vm.role_name,"id":"user-role-dropdown","autocomplete":"off","rules":[_vm.required('Role')],"menu-props":{ bottom: true }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',{staticClass:"body-2 text-black--80 font--primary"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(item))+" ")])],1)]}}]),model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}}),_c('v-divider',{staticClass:"mb-6 border-light-grey"})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"px-4 button font-weight-bold white-btn-text",attrs:{"type":"submit","color":"primary","loading":_vm.isLoading,"disabled":!_vm.isFormValid}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }