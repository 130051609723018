<template>
  <v-row class="d-flex justify-center">
    <v-col cols="12" md="10" lg="10">
      <card-widget bodyStyle="px-4 pb-0">
        <template #cardBody>
          <v-row class="d-flex justify-end mb-8" no-gutters>
            <v-col cols="12" md="9" lg="9" class="text-left">
              <h6 class="text-h6 font-weight-large pb-2">
                {{ $dictionary.app.users.index.title }}
              </h6>
              <p class="subtitle-1 text--secondary primary--font mb-0">
                {{ accountSubTitle }}
              </p>
            </v-col>
            <v-col cols="3" md="3" class="d-flex justify-end">
              <template v-if="isAdmin">
                <div class="pr-2">
                  <v-select
                    dense
                    outlined
                    label="Role"
                    item-value="value"
                    v-model="userRoleFilter"
                    @change="getAccountUsers()"
                    :items="rolesDropdownList"
                    class="user-roles__filter body-2"
                    item-color="primary custom-dropdown"
                    :menu-props="{ bottom: true, overflowY: true, top: false }"
                    :item-text="({ role }) => $options.filters.capitalize(role)"
                  />
                </div>
                <v-btn
                  color="primary"
                  v-text="'Add user'"
                  @click="onAddUser(true)"
                  class="px-4 button font-weight-bold white-btn-text"
                />
              </template>
            </v-col>
          </v-row>

          <users-table
            :isLoading="loading"
            :isAdmin="isAdmin"
            :data="users"
            @get-records="getAccountUsers"
            :table-settings="settings"
            :update-settings="updateSettings"
            @edit-user="editUser"
          />
        </template>
      </card-widget>

      <edit-permission-form
        :user="user"
        :roles="roles"
        @get-users="getAccountUsers"
        :isDrawerOpen="editUserDrawer"
        @close-nav-drawer="editUserDrawer = $event"
      />

      <add-user-form
        :roles="roles"
        @get-users="getAccountUsers"
        :add-user-drawer="addUserDrawer"
        @close-nav-drawer="onAddUser"
      />
    </v-col>
  </v-row>
</template>

<script>
import AddUserForm from "./AddUserForm.vue";
import { mapActions, mapGetters } from "vuex";
import { UsersTable } from "@/components/tables";

import { ROLES } from "@/constants";
import { getUsers } from "@/services";
import { capitalize } from "@/filters";

import { CardWidget } from "@/components/widgets";
import EditPermissionForm from "./EditPermissionForm.vue";
import { hasProp, isEmpty } from "@/utils/common.utils";

/**
 * Account settings users tab
 */
export default {
  name: "Users",
  /**
   * ---------------- Components ------------------
   */
  components: {
    AddUserForm,
    EditPermissionForm,
    UsersTable,
    CardWidget,
  },
  /**
   * ------------ Data Properties-------------
   */
  data() {
    return {
      hasErrors: false,
      users: [],
      user: {},
      roles: [],
      loading: false,
      addUserDrawer: false,
      editUserDrawer: false,
      allUsersFilter: { role: "All Users", value: -1 },
      userRoleFilter: -1,
      usersParams: {},
    };
  },

  /***
   * ---------------Watch --------------------
   */
  watch: {
    "selectedAccount.account_id"(val) {
      this.getAccountUsers({ account_id: val });
    },
  },
  /**
   * ---------Methods ------------
   */
  methods: {
    capitalize,
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
      updateSettings: "settings/setUsersSettings",
      getRoles: "app/getRoles",
    }),
    /**
     * Update addUserDrawer  on click addUser button
     */
    onAddUser(val) {
      this.addUserDrawer = val;
      this.editUserDrawer = false;
    },

    /**
     * Open Edit drawer
     * @param {Object} item User details
     */
    editUser({ role, ...item }) {
      this.user = item;

      this.user.role = this.$options.filters.capitalize(role);
      this.editUserDrawer = true;
    },

    /**
     * Get list of user based on account Id
     * @param {String} account_id Account Id of current selected Account
     */
    async getAccountUsers(params = {}) {
      try {
        this.loading = true;
        !isEmpty(params) &&
          (this.usersParams = { ...this.usersParams, ...params });
        this.usersParams.account_id =
          this.usersParams.account_id ?? this.selectedAccount?.account_id;

        if (this.userRoleFilter > -1)
          this.usersParams["q[role_eq]"] = this.userRoleFilter;
        else {
          hasProp(this.usersParams, "q[role_eq]") &&
            delete this.usersParams["q[role_eq]"];
        }

        let { data } = await getUsers(this.usersParams);
        data = data ?? [];

        // Filters the current logged in user from the user list which is to be shown in the users table
        this.users = data.filter(
          ({ email }) =>
            email?.toLowerCase() !== this.currentUser?.email?.toLowerCase()
        );
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: this.$errorMessage(error),
          type: this.$appConfig.snackbar.snackbarTypes.error,
        });
      } finally {
        this.loading = false;
      }
    },

    /**
     * Get listing of roles
     */
    async getRolesList() {
      this.rolesLoading = true;
      await this.getRoles();
      this.capitalizeRoles();
      this.rolesLoading = false;
    },
    /**
     * Capitalizes role names in roles array
     */
    capitalizeRoles() {
      this.roles = this.rolesList
        ? this.rolesList.map((role) => capitalize(role))
        : this.roles;
    },
  },
  /**
   * ---------Computed properties ------------------
   */
  computed: {
    ...mapGetters({
      selectedAccount: "ui/selectedAccount",
      settings: "settings/users",
      currentUser: "auth/currentUser",
      isAdmin: "auth/isAdmin",
      rolesList: "app/roles",
    }),
    /**
     * Roles dropdown list with its value
     */
    rolesDropdownList() {
      const mapper = (role, value) => ({ role, value });
      const rolesArr = Object.values(ROLES).map(mapper);

      rolesArr.unshift(this.allUsersFilter);
      return rolesArr;
    },
    /**
     * Computed the subtitle of this tab which contains the selected account name
     * @type {String}
     */
    accountSubTitle() {
      const REPLACE_TAG = "<<account>>";
      const { subTitle } = this.$dictionary.app.users.index;
      return subTitle.replace(REPLACE_TAG, this.selectedAccount?.account_name);
    },
  },
  /**
   * -------------Created Lifecycle ----------------------
   */
  created() {
    this.getRolesList();
  },
};
</script>

<style lang="scss" scoped>
.user-roles__filter {
  width: 150px;
  height: 36px;
}
</style>
